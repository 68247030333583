import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { ContentType, ModuleDriver } from '@d24/modules/front';
import { RelatedServiceType } from '@service/related';
import { PageBuilder } from '@component/PageBuilder';
import { Article } from '../components/Article';
import { Content } from '../models/content';
import { ContentRepositoryType } from '../contracts';
import { SupportedFormatsMiddlewareType } from '../services';
import { detectSupportedFormats } from '../helpers';
import { Inject } from '@plugin/inversify';
import { SiteServiceType } from '@service/site';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let SimplePreviewView = class SimplePreviewView extends Vue {
    constructor() {
        super(...arguments);
        this.content = null;
        this.modules = [];
        this.relatedService = null;
        this.result = null;
    }
    composeContentProperties() {
        if (!this.result) {
            return {};
        }
        return {
            ...this.result.content.properties,
            paywall: this.result.content.contentProperties.paywall,
            site: this.siteService.getActiveSite(),
            videoAdsTag: this.siteService.getAdsConfig().videoAdsTag
        };
    }
    buildContent() {
        if (!this.result) {
            return;
        }
        this.$logger('🏷 SimplePreviewView.buildContent()', 'debug');
        this.content = Content.hydrate({
            ...this.result.content,
            properties: this.composeContentProperties()
        });
        this.modules = [...(this.content.slots.main || [])].filter(m => m.driver !== ModuleDriver.AdBanner);
        this.$logger(`Loaded content - ${this.content.title}`, 'debug');
        if (typeof this.$container === 'undefined') {
            throw new Error('WithLayout.composeContent(): [this.$container] is undefined!');
        }
        this.relatedService = this.$container.get(RelatedServiceType);
        this.relatedService.storeRelated({ ...this.result.related });
        this.$logger(`🏷 Created new RelatedService instance with data: ${JSON.stringify(this.result.related)}`, 'debug', false);
    }
};
__decorate([
    Inject(SiteServiceType)
], SimplePreviewView.prototype, "siteService", void 0);
SimplePreviewView = __decorate([
    Component({
        name: 'SimplePreviewView',
        components: { Article, PageBuilder },
        provide: {
            lazyImages: false
        },
        created() {
            this.buildContent();
        },
        metaInfo() {
            return this.result && this.result.content
                ? {
                    title: this.result.content.title,
                    meta: this.result.meta
                } : {};
        },
        async asyncData(context) {
            if (!context.app.$route.query.secret) {
                context.error('Secret not provided.', 400);
            }
            const repository = context.app.$container.get(ContentRepositoryType);
            const supportedFormats = context.app.$container.get(SupportedFormatsMiddlewareType);
            if (!context.app.$store.getters['content/hasSupportedFormats']) {
                const supportedFormats = detectSupportedFormats(context);
                context.app.$store.commit('content/addSupportedFormat', supportedFormats);
            }
            if (!supportedFormats.hasSupportedFormats()) {
                supportedFormats.setSupportedFormats(context.app.$store.getters['content/supportedFormats']);
            }
            try {
                const result = await repository.load(context.app.$route.params.pathMatch || '', { secret: `${context.app.$route.query.secret}` });
                if (!result || ![ContentType.Article, ContentType.LiveArticle].includes(result.content.type)) {
                    context.error('Cannot use SimplePreview for non-article types.', 409);
                }
                return { result };
            }
            catch (e) {
                context.error(`[SimplePreview]: An error occurred - ${e.message}`, e.status);
                return { result: null };
            }
        }
    })
], SimplePreviewView);
export { SimplePreviewView };
export default SimplePreviewView;
